<template>
	<div class="page_container">
		<!-- AI文案 -->
		<!-- <div class="Copywriting"> -->
			<!-- <div class="writLeft">
				<router-link to="/write">
					<img src="../assets/imgs/zuo.png" alt="" />
				</router-link>
			</div> -->
			<!-- <div class="writRight">
				<div v-for="(item, index) in recommend" :key="index">
					<div class="rightBox">
						<p>{{ item.name }}</p>
						<span> {{ item.desc }}</span>
					</div>
				</div>
			</div> -->
			<!-- <div v-for="(item, index) in classifyData" :key="'first_' + item.id">
				<div
					v-if="item.id == classifySelected"
					style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr">
					<div
						v-for="(item1, index1) in item.children"
						:key="'second_' + item1.id"
						@click="classifyItem(item1.id)"
						style="margin-top: 15px">
						<div class="footer_box">
							<div style="display: flex; margin-bottom: 10px">
								<div class="icon">
									
									<img :src="item1.image" alt="" />
								</div>
								<h6 style="margin-left: 10px; margin-top: 15px">
									{{ item1.title }}
								</h6>
								
							</div>
							<p class="c-line-3" style="color: #c9c9c9">
								{{ item1.desc }}
							</p>
							<div class="collect" v-if="token" @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)">
								<a-icon
									type="star"
									theme="filled"
									:style="{
										fontSize: '18px',
										color: item1.isVote == 0 ? '#333' : '#1890ff',
										display: 'block',
									}" />
							</div>
							<div class="jiantou">
								<a-icon type="arrow-right" class="arrow-right" />
							</div>
						</div>
					</div>
				</div>
			</div> -->
		<!-- </div> -->

		<!-- AI绘画 -->
		<!-- <div class="drawing_coopen" :class="[isCoopen ? 'show' : 'hide']">
			<div class="info_box">
				<div class="title">{{ config.name }} · 创作平台</div>
				<div class="title">为伴侣创造Ai头像</div>
				<p>即将推出Ai伴侣的朋友圈、视频功能（开发中...）</p>
			</div>
			<div class="flow_box">
				<div class="list_box" v-if="flowData.length">
					<div class="list list_wrapper" :id="'list_' + (index + 1)" v-for="(item, index) in flowData" :key="'flow_' + index">
						<div class="move_box move_content" :id="'move_' + (index + 1)">
							<div class="item" v-for="(img, iIndex) in item" :key="'flow_img_' + iIndex" @click="lookImage(img.image, img.width, img.height, img.desc)">
								<img :src="img.image" alt="" />
								<div class="coverage">
									<div class="c-line-2">{{ img.desc }}</div>
									<div class="count">
										<div class="icon">
											<a-icon type="heart" :theme="iconTheme" />
											<span>{{ img.votes }}</span>
										</div>
										<div class="icon">
											<a-icon type="like" :theme="iconTheme" />
											<span>{{ img.likes }}</span>
										</div>
										<div class="icon">
											<a-icon type="eye" :theme="iconTheme" />
											<span>{{ img.views }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="move_box fakeMove_content" :id="'fakeMove_' + (index + 1)"></div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- class="drawing_atlas" :class="[!isCoopen ? 'show' : 'hide']" -->
		<div class="drawing_atlas" id="drawing_atlas" @scroll="scrollView">
			<div class="info_box">
				<!-- <div class="title">{{ config.name }} · 创作平台</div> -->
				<!-- <div class="title">为网红画一张美照</div>
        <p>即将推出Ai网红语音电话、视频通话（开发中…）</p> -->
			</div>
			<!-- <div class="info_box">
				<div class="title">{{ config.name }} · 创作平台</div>
				<p>
					即刻拥有一款神奇的AI绘画产品，让您不再担心没有艺术天赋！<br />
					只要输入文字或上传图片，它就会自动为您创作出真正美丽的太极拳壁纸、卡通人物、“星空之境”等！<br />
					别犹豫了，这款产品定会是您办公、娱乐和生活的最佳良伴！
				</p>
			</div> -->
			<div class="tabs_box">
				<div
					class="tabs"
					v-for="(item, index) in drawingData"
					:key="'tabs_' + index"
					@click="jumpView(item.mode, item.id)">
					<!-- <img :src="item.image" alt="" /> -->
					<div class="title">{{ item.title }}</div>
					<p>{{ item.desc }}</p>
				</div>
			</div>
			<div class="atlas_box">
				<div class="list_box">
					<div class="list">
						<waterfall :col="4" :gutterWidth="20" :data="atlasData">
							<div
								class="item"
								v-for="(item, index) in atlasData"
								:key="'atlas_img_' + item.id"
								@click="lookImage(item.image, item.width, item.height, item.desc)">
								<div class="img">
									<img :src="item.image" alt="" />
									<div class="coverage">
										<div class="c-line-2">{{ item.desc }}</div>
										<div class="time">{{ item.createtime }}</div>
									</div>
								</div>
								<div class="infos" v-if="item.image">
									<div class="left">
										<!-- <div class="avatar">
											<img :src="item.avatar" alt="" />
										</div>
										<div class="nickname c-line-1">{{ item.nickname }}</div> -->
									</div>
									<div class="right">
										<!-- <div class="icons">
											<a-icon type="heart" :theme="iconTheme" />
											<span>{{ item.votes }}</span>
										</div>
										<div class="icons">
											<a-icon type="like" :theme="iconTheme" />
											<span>{{ item.likes }}</span>
										</div> -->
										<div class="icons">
											<a-icon type="eye" :theme="iconTheme" />
											<span>{{ item.views }}</span>
										</div>
									</div>
								</div>
							</div>
						</waterfall>
					</div>
				</div>
			</div>
		</div>
		<div class="arrow" v-if="isCoopen" @click="isCoopen = false">
			<a-icon type="down-circle" :style="{ fontSize: '40px', color: 'rgba(255, 255, 255, 0.5)' }" />
		</div>
		<CardPreviewImage :show="previewImageShow" :data="previewImageData" @close="previewImageShow = false">
		</CardPreviewImage>
	</div>
</template>

<script>
	import CardPreviewImage from "@/components/Cards/CardPreviewImage.vue";
	import { Debounce, regroup } from "@/utils/tools";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	import router from "@/router";
	import { checkCard } from "@/utils/verify";
	export default {
		components: { CardPreviewImage },
		data() {
			return {
				isCoopen: true, // 开屏页状态
				isScroll: true, // 滚动条状态
				flowData: [],
				atlasData: [],
				iconTheme: "outlined", // outlined / filled
				previewImageShow: false,
				previewImageData: {
					desc: "",
					image: "",
					width: "",
					height: "",
				},
				page: 1,
				pagesize: 20,
				drawingData: [
					{
						id: "more",
						title: "艺术二维码生成",
						desc: "敬请期待",
					},
					{
						id: "more",
						title: "Ai写真",
						desc: "敬请期待",
						// image: require("@assets/icons/tabs3.png"),
					},
				],

				recommend: [
					{
						name: "推品文案 ",
						desc: "解决你的文案难题",
					},
					{
						name: "周报生成",
						desc: "输入工作内容，帮你快速完成周报",
					},
					{
						name: "小红书创作",
						desc: "创作属于您的爆火原创文案",
					},
					{
						name: "Ai角色生成器 ",
						desc: "快速生成Ai角色",
					},
				],
				classifyData: [],
			};
		},
		watch: {
			previewImageShow(newValue, oldValue) {
				newValue && this.$notification.destroy();
			},
		},
		computed: {
			...mapGetters("app", ["config"]),
			...mapGetters("user", ["token"]),
		},
		created() {
			this.getAtlasPhoto("index", 1, 50);
			this.getAtlasPhoto("list", 1, 20);
			this.getDrawingClassify();
			this.getFirstClassify(); //获取文案一级分类
		},
		mounted() {
			window.addEventListener("mousewheel", this.handleMousewheel, false);
		},
		beforeDestroy() {
			window.removeEventListener("mousewheel", this.handleMousewheel, false);
		},
		methods: {
			// 获取绘图分类
			getDrawingClassify() {
				this.$http("drawing.first").then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, title, desc, image, mode }) => ({
								id,
								title,
								desc,
								image,
								mode,
							}));
							this.drawingData.unshift(...arr);
						}
					}
				});
			},
			// 展览图片
			getAtlasPhoto(type, page, pagesize) {
				// 滚动图片
				function scrollPhoto(num, dom1, dom2, dom3) {
					const wrapper = document.getElementById(dom1);
					const content = document.getElementById(dom2);
					const fakeContent = document.getElementById(dom3);
					function roll(t) {
						fakeContent.innerHTML = content.innerHTML;
						// 开始无滚动时设为0
						wrapper.scrollTop = 0;
						// 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
						let timer = setInterval(rollStart, t);
						// 鼠标移入div时暂停滚动
						wrapper.onmouseover = function () {
							clearInterval(timer);
							timer = null;
						};
						// 鼠标移出div后继续滚动
						wrapper.onmouseout = function () {
							timer = setInterval(rollStart, t);
						};
					}
					// 开始滚动函数
					function rollStart() {
						if (num % 2 == 0) {
							// 正常滚动不断给scrollTop的值-1, 当滚动高度大于列表内容高度时恢复为0
							if (Math.abs(wrapper.scrollTop) >= content.scrollHeight) {
								wrapper.scrollTop = 0;
							} else {
								wrapper.scrollTop--; // 倒序
							}
						} else {
							// 正常滚动不断给scrollTop的值+1, 当滚动高度大于列表内容高度时恢复为0
							if (wrapper.scrollTop >= content.scrollHeight) {
								wrapper.scrollTop = 0;
							} else {
								wrapper.scrollTop++; // 正序
							}
						}
					}
					roll(20);
				}

				this.$http("drawing.atlas", { type, page, pagesize }).then((res) => {
					if (res.code === 1) {
						if (type === "index") {
							if (res.data && res.data.length) {
								this.flowData = regroup(res.data, 5);
								const timer = setTimeout(() => {
									for (let index = 1; index <= 5; index++) {
										scrollPhoto(index, `list_${index}`, `move_${index}`, `fakeMove_${index}`);
									}
									clearTimeout(timer);
								}, 0);
							}
						}

						if (type === "list") {
							if (res.data && res.data.length) {
								this.atlasData = this.atlasData.concat(res.data);
							} else {
								this.page--;
							}
						}
					}
				});
			},
			// 查看图片
			lookImage(img, w, h, desc) {
				this.previewImageData = {
					desc,
					image: img,
					width: w,
					height: h,
				};
				this.previewImageShow = true;
			},
			// 跳转视图
			jumpView(val, id) {
				switch (val) {
					case "text2img":
						this.$router.push("/drawing/canvas?id=" + id);
						break;
					case "img2img":
						this.$router.push("/drawing/picture?id=" + id);
						break;
					default:
						// location.reload()
						// router.push("/"); // 跳转
						// this.$message.warning("正在开发中，敬请期待")
						break;
				}
			},
			// 鼠标滚轮
			handleMousewheel: Debounce(function (e) {
				const direction = e.deltaY > 0 ? "down" : "up";
				if (direction === "down") {
					this.isCoopen && (this.isCoopen = false);
				} else {
					!this.isCoopen && this.isScroll && (this.isCoopen = true);
				}
			}, 20),
			// 滚动条
			scrollView: Debounce(
				function (e) {
					this.isScroll = e.target.scrollTop <= 0 ? true : false;
					let scrollTop = e.target.scrollTop;
					let clientHeight = e.target.clientHeight;
					let scrollHeight = e.target.scrollHeight;
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						this.page++;
						this.getAtlasPhoto("list", this.page, this.pagesize);
					}
				},
				20,
				false,
			),

			// 文案
			// 获取收藏分类
			getCollectClassify() {
				this.$http("write.collectList").then((res) => {
					if (res.code === 1) {
						let arr = [];
						res.data.map((item) => {
							const {
								prompt: { id, title, desc, image, isVote },
							} = item;
							arr.push({ id, title, desc, image, isVote });
						});
						this.classifyData.map((item) => {
							if (item.id === "collect") {
								item.children = arr;
								item.count = item.children.length;
							}
						});
						// console.log("获取收藏分类", this.classifyData);
					}
				});
			},
			// 获取一级分类
			getFirstClassify() {
				this.$http("write.first").then((res) => {
					if (res.code === 1) {
						let arr = [];
						res.data.write.map((item, index) => {
							arr.push({
								id: item.id,
								title: item.title,
								children: [],
								count: item.count,
							});
						});
						this.classifyData = arr;
						this.classifyData.unshift({
							id: "0",
							title: "全部",
							children: [],
							count: res.data.acount_all,
						});
						this.classifySelected = arr[0].id;
						this.getSecondClassify();

						if (this.token) {
							this.classifyData.unshift({
								id: "collect",
								title: "收藏",
								children: [],
								count: 0,
							});
							this.getCollectClassify();
						}
						// this.classifyData[1].children = this.classifyData[1].children.splice(0, 4);
					}
				});
			},
			// 获取二级分类
			getSecondClassify() {
				if (this.classifySelected === "collect" && this.token) {
					this.getCollectClassify();
					return;
				}
				this.$http("write.second", { topic_id: this.classifySelected }).then((res) => {
					if (res.code === 1) {
						this.classifyData.map((item, index) => {
							if (item.id == this.classifySelected) {
								item.children = res.data.map(({ id, title, desc, image, isVote }) => ({
									id,
									title,
									desc,
									image,
									isVote,
								}));
							}
						});
						if (this.token) {
							this.classifyData[1].children = this.classifyData[1].children.splice(0, 4);
						} else {
							this.classifyData[0].children = this.classifyData[0].children.splice(0, 4);
						}
					}
				});
			},
			// 收藏
			collectWriteItem(pid, cid, vote) {
				this.$http("write.collect", { prompt_id: cid }).then((res) => {
					if (res.code === 1) {
						this.classifyData.map((item) => {
							if (item.id == pid) {
								item.children.map((item1) => {
									if (item1.id == cid) {
										item1.isVote = vote == 0 ? 1 : 0;
									}
								});
							}
						});
						this.$message.success(res.msg);
						this.getCollectClassify();
					}
				});
			},
			// 进入当前分类
			classifyItem(id) {
				console.log("进入当前分类");
				this.$router.push("/write/create?id=" + id);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page_container {
		height: 0;
		min-height: calc(100% - 24px);
		//   background-image: url("../assets/imgs/drawing_bg.png");
		//   background: #fff;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		overflow: hidden;
		position: relative;
		color: #141414;
		margin-bottom: 24px;

		.arrow {
			position: absolute;
			bottom: 30px;
			left: 50%;
			z-index: 100;
			transform: translateX(-50%);
			cursor: pointer;

			animation: identifier 3s linear infinite;

			@keyframes identifier {
				0% {
					transform: translateX(-50%) scale(1) translateY(0);
				}

				50% {
					transform: translateX(-50%) scale(1) translateY(10px);
				}

				100% {
					transform: translateX(-50%) scale(1) translateY(0);
				}
			}
		}

		.drawing_coopen {
			width: 100%;
			height: 100%;
			border: 1px solid transparent;
			transition: all 0.5s ease;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			padding-bottom: 20px;

			&.show {
				height: 100%;
				padding-top: 120px;

				.info_box {
					transform: scale(1);
				}
			}

			&.hide {
				height: 0;
				padding-top: 0;
				padding-bottom: 0;

				.info_box {
					transform: scale(0);
				}
			}

			.info_box {
				//   color: #fff;
				color: #141414;
				text-align: center;
				transition: all 0.3s ease;
				font-family: "YouSheBiaoTiHei";
				letter-spacing: 2px;

				.title {
					font-size: 50px;
				}

				p {
					letter-spacing: 5px;
					margin-bottom: 0;
				}
			}

			.flow_box {
				margin-top: 100px;
				flex: 1;
				mask-image: linear-gradient(0deg, transparent, #fff 170px);
				-webkit-mask-image: linear-gradient(0deg, transparent, #fff 170px);
				-webkit-mask-composite: source-out;

				.list_box {
					height: 0;
					min-height: 100%;
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					padding: 0 20px;

					mask-image: -webkit-gradient(
						linear,
						left top,
						right top,
						color-stop(0%, rgba(255, 255, 255, 0)),
						color-stop(10%, rgb(255, 255, 255)),
						color-stop(90%, rgb(255, 255, 255)),
						color-stop(100%, rgba(255, 255, 255, 0))
					);
					-webkit-mask-image: -webkit-gradient(
						linear,
						left top,
						right top,
						color-stop(0%, rgba(255, 255, 255, 0)),
						color-stop(10%, rgb(255, 255, 255)),
						color-stop(90%, rgb(255, 255, 255)),
						color-stop(100%, rgba(255, 255, 255, 0))
					);
					-webkit-mask-composite: source-out;

					.list {
						width: calc((100% - 80px) / 5);
						mask-image: linear-gradient(180deg, transparent, #fff 170px);
						-webkit-mask-image: linear-gradient(180deg, transparent, #fff 170px);
						-webkit-mask-composite: source-out;
						overflow: hidden;
						display: flex;

						&:nth-child(2n - 1) {
							margin-top: 100px;
							flex-direction: column;
						}

						&:nth-child(2n) {
							flex-direction: column-reverse;
						}

						.move_box {
							.item {
								margin-bottom: 20px;
								border-radius: 10px;
								overflow: hidden;
								cursor: pointer;
								position: relative;

								&:hover {
									.coverage {
										display: flex;
									}
								}

								.coverage {
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									background: rgba($color: #000000, $alpha: 0.5);
									display: none;
									flex-direction: column;
									justify-content: flex-end;
									padding: 10px 10px;
									font-size: 12px;

									.count {
										margin-top: 8px;
										display: flex;
										align-items: center;
										color: #a5adb7;

										.icon {
											display: flex;
											align-items: center;
											margin-right: 8px;

											span {
												margin-left: 4px;
											}
										}
									}
								}

								img {
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
			}
		}

		.drawing_atlas {
			width: 100%;
			height: 100%;
			padding: 40px 12px;
			overflow: hidden;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				// height: 10px;
			}

			&.hide {
				.info_box {
					transform: scale(0);
				}
			}

			&.show {
				.info_box {
					transform: scale(1);
				}
			}

			.info_box {
				color: #fff;
				color: #141414;
				text-align: center;
				transition: all 0.5s ease;
				font-family: "YouSheBiaoTiHei";
				letter-spacing: 2px;

				.title {
					font-size: 50px;
				}
			}

			.tabs_box {
				// width: 1100px;
				width: 100%;
				margin: 20px auto;
				display: flex;
				// flex-wrap: wrap;
				font-family: "YouSheBiaoTiHei";

				.tabs {
					width: calc((100% - 80px) / 4);
					height: 120px;
					border-radius: 8px;
					background: rgba(102, 133, 253, 0.15);
					// border: 1px solid rgba(102, 133, 253, 1);
					box-shadow: 0px 0px 3px rgba(66, 66, 220, 0.3);
					backdrop-filter: blur(18px);
					padding: 20px 30px;
					cursor: pointer;
					letter-spacing: 2px;
					margin-right: 25px;
					margin-bottom: 20px;

					&:nth-child(1) {
						background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01BWun571C5KMOnnhyJ_!!6000000000029-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(1):hover {
						background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01ASgTxb1Rm7bbdpBNV_!!6000000002153-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(2) {
						background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01HoGHXT1qdklMqhps8_!!6000000005519-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(2):hover {
						background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Xdq3391ieecD0DuK1_!!6000000004438-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(3) {
						background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01BWun571C5KMOnnhyJ_!!6000000000029-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(3):hover {
						background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01ASgTxb1Rm7bbdpBNV_!!6000000002153-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(4) {
						background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01HoGHXT1qdklMqhps8_!!6000000005519-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(4):hover {
						background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Xdq3391ieecD0DuK1_!!6000000004438-2-tps-904-400.png");
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					&:nth-child(4n) {
						margin-right: 0;
					}
					&:hover {
						background: rgba(102, 133, 253, 0.3);
						box-shadow: 0px 0px 30px rgba(0, 66, 220, 0.3);
						transform: translateY(-5%);
					}
					img {
						width: auto;
						height: 40px;
						margin-bottom: 10px;
					}

					.title {
						font-size: 24px;
						color: #fff;
					}

					p {
						margin-bottom: 0;
						color: #94989e;
					}
				}
			}

			.atlas_box {
				// width: 1100px;
				width: 100%;
				margin: 0 auto;

				.list_box {
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;

					.list {
						::v-deep .vue-waterfall-column {
							width: calc((100% - 60px) / 4);
						}

						.item {
							margin-bottom: 20px;

							.img {
								position: relative;
								border-radius: 8px;
								overflow: hidden;
								cursor: pointer;

								&:hover {
									.coverage {
										display: flex;
									}
								}

								img {
									width: 100%;
									height: auto;
									border-radius: 8px;
								}

								.coverage {
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									background: rgba($color: #000000, $alpha: 0.5);
									display: none;
									flex-direction: column;
									justify-content: flex-end;
									padding: 10px 10px;
									font-size: 12px;
									color: #fff;

									.time {
										color: #a5adb7;
										margin-top: 8px;
									}
								}
							}

							.infos {
								margin-top: 10px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								color: #838fa2;
								font-size: 12px;

								.left {
									display: flex;
									align-items: center;

									.avatar {
										width: 22px;
										height: 22px;
										border-radius: 50%;
										overflow: hidden;
										margin-right: 8px;

										img {
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}

									.nickname {
										max-width: 100px;
									}
								}

								.right {
									display: flex;
									align-items: center;

									.icons {
										display: flex;
										align-items: center;
										margin-left: 8px;

										span {
											margin-left: 2px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	//
	.Copywriting {
		width: 100%;
		height: 360px;
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 20px;

		.writLeft {
			// width: 80%;
			width: 1050px;
			max-width: 1050px;
			// background-image: url('../assets/imgs/zuo.png');
			// background-size: cover;
			// background-repeat: no-repeat;
			img {
				width: 100%;
				height: 100%;
				border-radius: 20px;
			}
		}
	}

	.footer_box {
		width: 250px;
		height: 165px;
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;
		margin-left: 5px;
		padding-bottom: 15px;

		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
			transform: translateY(-5%);
		}

		.icon {
			// width: 320px;
			width: 80px;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: #1890ff;
			// margin-bottom: 15px;
			// overflow: hidden;
			border: 1px solid #e2e1e1;
			border-radius: 15px;

			svg path {
				fill: #1890ff;
			}

			img {
				width: 60%;
				height: auto;
			}
		}

		h6 {
			color: #000;
			text-align: center;
			// margin-bottom: 50px;
		}

		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 30px;
		}

		.collect {
			position: absolute;
			top: 10px;
			right: 10px;
		}

		.jiantou {
			position: absolute;
			top: 80px;
			right: 15px;

			.arrow-right {
				color: #fff;
			}
		}
	}

	.footer_box:hover {
		.arrow-right {
			color: #1890ff;
		}
	}
</style>
