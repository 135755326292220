<template>
	<div>
		<div class="preview_img" v-if="status">
			<div class="bg" :style="{ backgroundImage: `url(${data.image})` }"></div>
			<div class="img_aside">
				<div class="aside_item">
					<div class="title">
						<div class="text">创作灵感</div>
						<div class="line"></div>
					</div>
					<div class="content">
						<div class="text_box">{{ data.desc }}</div>
					</div>
				</div>
				<div class="aside_item">
					<div class="title">
						<div class="text">画作预览</div>
						<div class="line"></div>
					</div>
					<div class="content">
						<div class="thumb" :style="{ width: thumbImageSize.w + 'px', height: thumbImageSize.h + 'px' }">
							<img :src="data.image" alt="" />
						</div>
					</div>
				</div>
				<div class="aside_item">
					<div class="title">
						<div class="text">画作尺寸</div>
						<div class="line"></div>
					</div>
					<div class="content">
						<!-- <div class="text_box">{{ data.width }} x {{ data.height }}</div> -->
						<div class="text_box">1024 x 1024</div>
					</div>
				</div>
			</div>
			<div class="img_content">
				<div
					class="image"
					:style="{
						transform: `scale(${scale})`,
						width: previewImageSize.w + 'px',
						height: previewImageSize.h + 'px',
					}">
					<!-- crossorigin="Anonymous"  -->
					<img :src="data.image" alt="" />
				</div>
				<div class="close" @click="closeHandle">
					<a-icon type="close" />
				</div>
				<div class="tools">
					<!-- <a-tooltip placement="left">
						<template slot="title">
							<span>收藏</span>
						</template>
						<div class="item" @click="collectHandle">
							<a-icon type="heart" theme="filled" :style="{ fontSize: '18px', color: collect ? '#FF3434' : '' }" />
						</div>
					</a-tooltip> -->
					<a-tooltip placement="left">
						<template slot="title">
							<span>下载</span>
						</template>
						<div class="item" @click="downloadHandle">
							<a-icon type="download" />
						</div>
					</a-tooltip>
					<a-tooltip placement="left">
						<template slot="title">
							<span>放大</span>
						</template>
						<div class="item" @click="scaleHandle(0.1)">
							<a-icon type="zoom-in" />
						</div>
					</a-tooltip>
					<a-tooltip placement="left">
						<template slot="title">
							<span>缩小</span>
						</template>
						<div class="item" @click="scaleHandle(-0.1)">
							<a-icon type="zoom-out" />
						</div>
					</a-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { create, all } from "mathjs";
	const math = create(all, {
		number: "BigNumber",
		precision: 20,
	});
	import { downloadPicture, sizePicture, downloadByBlob } from "@/utils/tools";
	export default {
		name: "CardPreviewImage",
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				status: this.show,
				collect: false,
				scale: 1,
				aaa: "",
			};
		},
		watch: {
			show(newValue, oldValue) {
				this.status = newValue;
				if (newValue) {
					this.stop();
				} else {
					this.move();
				}
			},
		},
		computed: {
			previewImageSize() {
				return sizePicture(false, this.data.width, this.data.height);
			},
			thumbImageSize() {
				return sizePicture(false, this.data.width, this.data.height, 84);
			},
		},
		created() {},
		mounted() {},
		methods: {
			// 下载
			downloadHandle() {
				// this.downloadPicture(this.data.image, "wow.jpg"); //有成功的几率
				this.data.image && downloadPicture(this.data.image)
			},
			// 缩放
			scaleHandle(value) {
				const number = +math.format(math.add(math.bignumber(this.scale), math.bignumber(value)));
				if (number > 2 || number < 0.5) return;
				this.scale = number;
			},
			// 收藏
			collectHandle() {
				this.collect = !this.collect;
				this.$message.warning("正在开发中，敬请期待");
			},
			// 关闭
			closeHandle() {
				this.$emit("close", false);
			},
			// 禁止滚动
			stop() {
				var mo = function (e) {
					e.preventDefault();
				};
				document.body.style.overflow = "hidden";
				document.addEventListener("touchmove", mo, false);
			},
			// 开启滚动
			move() {
				var mo = function (e) {
					e.preventDefault();
				};
				document.body.style.overflow = "";
				document.removeEventListener("touchmove", mo, false);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.preview_img {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
		background: #000;
		color: #fff;

		.bg {
			position: absolute;
			top: -5%;
			left: -5%;
			z-index: -1;
			width: 110%;
			height: 110%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			filter: blur(100px);
		}

		.img_aside {
			width: 380px;
			height: 100%;
			background: rgba($color: #000000, $alpha: 0.2);
			backdrop-filter: blur(50px);
			padding: 40px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			.aside_item {
				margin-bottom: 20px;
				.title {
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					margin-bottom: 20px;
					.text {
						font-size: 18px;
						font-weight: 700;
					}

					.line {
						flex: 1;
						height: 1.5px;
						background: rgba($color: #fff, $alpha: 0.1);
						margin-left: 10px;
					}
				}

				.content {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.text_box {
						background: rgba($color: #fff, $alpha: 0.1);
						padding: 10px;
						border-radius: 8px;
						font-size: 14px;
					}

					.thumb {
						border-radius: 4px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}

		.img_content {
			width: calc(100% - 380px);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;

			.image {
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.close {
				width: 42px;
				height: 42px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 40px;
				right: 40px;
				z-index: 2;
				cursor: pointer;
				font-size: 20px;
				border-radius: 50%;
				background: rgba($color: #000000, $alpha: 0.3);
				border: 1.5px solid rgba(151, 151, 151, 0.4);
			}

			.tools {
				width: 42px;
				height: 100%;
				position: absolute;
				top: 0;
				right: 40px;
				z-index: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.item {
					width: 42px;
					height: 42px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					background: #eceef5;
					color: #333;
					cursor: pointer;
					font-size: 20px;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						background: #e8eefd;
					}
				}
			}
		}
	}
</style>
